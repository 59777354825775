<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-leger-assets-overlay"
    class="elv-leger-assets-drawer"
    :before-close="onCheckDrawerStatus"
    :size="400"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-leger-assets-drawer-header__title">
        {{ t('common.asset') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-leger-assets-drawer-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <el-scrollbar v-loading="loading">
      <div class="elv-leger-assets-drawer-info">
        <div class="elv-content-assets-overview">
          <div class="elv-content-assets-overview-left">
            <IconWithTitleCell
              :icon="gainOrLossAssetInfo.platformIcon || defaultAssetIcon"
              :title="gainOrLossAssetInfo.platformName"
            />
            <span class="elv-content-assets-overview-symbol-num">{{ currentSymbol }}</span>
          </div>
          <SvgIcon
            v-show="assetShowTypeInfo?.showEdit"
            class="elv-content-assets-overview-edit"
            name="sources-edit"
            width="14"
            height="14"
            @click="onClickOpenEdit"
          />
          <span class="elv-content-assets-overview-symbol">{{ t(currentCategory) }}</span>
        </div>
        <div
          v-if="priceProviderCheckList.length || assetShowTypeInfo?.value.includes('DERIVATIVE')"
          class="elv-content-assets-item elv-content-assets-price"
        >
          <p class="content-title">{{ t('report.priceProvider') }}</p>
          <p
            class="content-price-price-provider"
            :class="{ 'content-price-price-provider-select': !assetShowTypeInfo?.value.includes('DERIVATIVE') }"
          >
            <ElvSelect
              v-if="!assetShowTypeInfo?.value.includes('DERIVATIVE')"
              v-model="currentPriceProviderId"
              :teleported="false"
              remote
              filterable
              remote-show-suffix
              reserve-keyword
              :disabled="isDisabledCheckPriceProvider"
              :is-show-suffix-info="true"
              width="352px"
              class="elv-add-assets-dialog-content-price-provider-select"
              popper-class="elv-add-assets-dialog-content-price-provider-popper"
              :placeholder="t('common.select')"
              :options="priceProviderCheckList"
              @change="changePriceProviderSelect"
            ></ElvSelect>
            <span v-else class="content-price-price-provider-derivative"
              ><img :src="gainOrLossAssetInfo.platformIcon" alt="" /> {{ gainOrLossAssetInfo?.priceProviderName }}</span
            >
          </p>
          <p v-if="tipShowType !== '0'" class="content-price-tip">
            <span v-if="tipShowType === '1'" class="content-price-price-provider-contain-tip"
              ><SvgIcon name="empty-warning" width="14" height="14" />{{
                t('valuation.assets.notIncludeAssetsPrice')
              }}</span
            >
            <span v-if="tipShowType === '2'"
              ><SvgIcon name="defaultPriceIcon" width="14" height="14" />
              {{ t('valuation.assets.priceProviderSource') }}
              <a class="waring-link" :href="'https://www.coingecko.com'" target="_blank">coingecko.com</a></span
            >
            <span v-if="tipShowType === '3' && gainOrLossAssetInfo?.priceProviderName != '-'"
              ><SvgIcon name="defaultPriceIcon" width="14" height="14" />
              {{ t('valuation.assets.priceProviderSource') }}
              <a class="waring-link" :href="'https://www.binance.com'" target="_blank">binance.com</a></span
            >
          </p>
        </div>
        <div v-if="assetShowTypeInfo?.showContract" class="elv-content-assets-item elv-content-assets-contract">
          <p v-if="contractList.length" class="content-title">{{ t('title.contract') }}</p>
          <div v-if="contractList.length" class="elv-content-assets-contract-content">
            <p v-for="(item, index) in contractList" :key="index" class="elv-content-assets-contract-content-item">
              <IconWithTitleCell :icon="item?.platform?.logo" :title="item?.contract_address || ''" />
              <SvgIcon
                class="sources-copy"
                name="sources-copy"
                width="12"
                height="12"
                @click="onClickedCopy(item.contract_address)"
              />
            </p>
          </div>
          <!-- <p v-else class="elv-content-assets-contract-empty">{{ t('message.noContractAddress') }}</p> -->
        </div>
        <div v-if="assetShowTypeInfo?.showMarginAsset" class="elv-content-assets-item elv-content-assets-margin-assets">
          <p class="content-title">{{ t('valuation.assets.marginAsset') }}</p>
          <p class="elv-margin-assets-content">
            <IconWithTitleCell
              :title="gainOrLossAssetInfo.showSymbol || ''"
              :icon="gainOrLossAssetInfo.marginAssetsIcon || ''"
            />
          </p>
        </div>
      </div>
    </el-scrollbar>
  </el-drawer>
  <ChangeAssetPriceProviderDialog
    ref="changeAssetPriceProviderDialogRef"
    @onConfirmChangeAssetPriceProvider="onConfirmChangeAssetPriceProvider"
    @onCancelChangeAssetPriceProvider="onCancelChangeAssetPriceProvider"
  />
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import ValuationApi from '@/api/ValuationApi'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { assetCategoryType } from '@/config/transactions'
import defaultAssetIcon from '@/assets/icons/submit-icon.svg'
import ChangeAssetPriceProviderDialog from './ChangeAssetPriceProviderDialog.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => ({})
  },
  priceProviderList: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['onOpenEditDialog', 'onUpdateAssetTableList'])

const { t } = useI18n()
const route = useRoute()
const { toClipboard } = useClipboard()

const loading = ref(false)
const assetsDetail: any = ref({})
const isShowEditDrawer = ref(false)
const currentPriceProviderId = ref('')
const currentBeforePriceProviderId = ref('')
const changeAssetPriceProviderDialogRef = useTemplateRef('changeAssetPriceProviderDialogRef')

const assetsCategoryOptions = [
  {
    label: t('valuation.assets.cryptocurrency'),
    value: ['CRYPTO_CURRENCY'],
    showContract: true,
    showMarginAsset: false,
    showEdit: false
  },
  {
    label: t('valuation.assets.stablecoin'),
    value: ['CRYPTO_STABLE_COIN'],
    showContract: true,
    showMarginAsset: false,
    showEdit: false
  },
  {
    label: t('valuation.assets.derivative'),
    value: ['DERIVATIVE'],
    showContract: false,
    showMarginAsset: true,
    showEdit: false
  },
  {
    label: t('project.costBasis.token'),
    value: ['CRYPTO_TOKEN'],
    showContract: true,
    showMarginAsset: false,
    showEdit: true,
    specialToken: {
      label: t('valuation.assets.cryptocurrency')
    }
  },
  {
    label: t('common.spam'),
    value: ['CRYPTO_NFT'],
    showContract: true,
    showMarginAsset: false,
    showEdit: false
  }
]

const assetShowTypeInfo = computed(() => {
  return assetsCategoryOptions.find((item) => item.value.includes(props.currentData.category))
})

const contractList = computed(() => {
  const list = assetsDetail.value?.underlyingCurrency?.formatDetailPlatForms?.filter(
    (item: any) => item?.contract_address
  )
  return list || []
})

const currentSymbol = computed(() => {
  return assetsDetail.value?.underlyingCurrency?.showSymbol || ''
})

const currentCategory = computed(() => {
  const category = assetCategoryType.find((item) => item.value === props.currentData?.category)
  return category?.label || '-'
})

const isSystemAsset = computed(() => {
  return props.currentData?.underlyingCurrency?.entityId === '0'
})

const gainOrLossAssetInfo = computed(() => {
  const assetData = {
    platformName: '-',
    platformIcon: '',
    priceProviderName: '-',
    marginAssetsIcon: '',
    showSymbol: ''
  }
  if (isEmpty(props.currentData?.underlyingCurrency) && isEmpty(props.currentData?.derivative)) {
    return assetData
  }
  if (isEmpty(props.currentData?.underlyingCurrency)) {
    assetData.platformName = props.currentData?.derivative?.name || '-'
    assetData.platformIcon = props.currentData?.derivative?.platform?.logo || ''
    assetData.priceProviderName = props.currentData?.derivative?.platform?.name || '-'
    assetData.marginAssetsIcon = props.currentData?.derivative?.marginAssetCurrency?.logo || ''
    assetData.showSymbol = props.currentData?.derivative?.marginAssetCurrency?.showSymbol || ''
  } else {
    assetData.platformName = props.currentData?.underlyingCurrency?.name || '-'
    assetData.platformIcon = props.currentData?.underlyingCurrency?.logo || ''
    assetData.priceProviderName = props.currentData?.underlyingCurrency?.name || '-'
    assetData.marginAssetsIcon = props.currentData?.underlyingCurrency?.logo || ''
    assetData.showSymbol = props.currentData?.underlyingCurrency?.showSymbol || ''
  }
  return assetData
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const priceProviderCheckList = computed(() => {
  if (!props.priceProviderList?.length) return []
  const notSpecial = {
    id: '0',
    value: '0',
    label: 'Not Specified',
    suffixName: t('valuation.price.addWithOutPrice'),
    alt: ''
  }
  const currentList = props.priceProviderList.map((item: any) => {
    let suffixShowName = ''
    suffixShowName =
      currentSymbol.value &&
      !item.userCurrencyList?.some((currencyItem: any) => currencyItem === currentSymbol.value) &&
      item.type !== 'API_COINGECKO'
        ? t('valuation.price.notCovered')
        : ''
    return {
      id: `${item.priceProviderId}`,
      value: `${item.priceProviderId}`,
      label: `${item.name}${item?.isDefault ? `(${t('common.default')})` : ''}`,
      suffixName: suffixShowName,
      alt: item.type || ''
    }
  })
  const index = currentList.findIndex((item) => item.alt === 'API_COINGECKO')
  const [templatePriceProvider] = currentList.splice(index, 1)
  currentList.unshift(notSpecial)
  if (templatePriceProvider && isSystemAsset.value) {
    currentList.push(templatePriceProvider)
  }
  return currentList
})

const isDisabledCheckPriceProvider = computed(() => {
  const currentPriceProvider = priceProviderCheckList.value.find((item) => item?.value === currentPriceProviderId.value)
  if (!currentPriceProvider) return false
  return (
    (currentPriceProvider.suffixName === '' && currentPriceProvider.value !== '0') ||
    currentPriceProvider.alt === 'API_COINGECKO'
  )
})

const tipShowType = computed(() => {
  let type = '0'
  const currentPriceProvider = priceProviderCheckList.value.find((item) => item.value === currentPriceProviderId.value)
  if (currentPriceProvider?.alt === 'API_COINGECKO') {
    type = '2'
    return type
  }
  if (assetShowTypeInfo.value?.value.includes('DERIVATIVE')) {
    type = '3'
    return type
  }
  if (currentPriceProvider?.suffixName === t('valuation.price.notCovered')) {
    type = '1'
    return type
  }
  return type
})

/**
 * 复制合约地址
 * @param {string} msg 合约地址
 */
const onClickedCopy = async (msg: any) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

/**
 * 切换资产详情弹窗显示状态
 */
const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
}

/**
 * 编辑资产弹窗
 */
const onClickOpenEdit = () => {
  emits('onOpenEditDialog', assetsDetail.value)
}

/**
 * 初始化数据
 */
const initData = () => {
  currentPriceProviderId.value =
    assetsDetail.value?.underlyingCurrency?.currencyPriceProviderMap?.priceProviderId || '0'
  currentBeforePriceProviderId.value = currentPriceProviderId.value
}

/**
 * 获取资产详情数据
 */
const fetchAssetsDetail = async () => {
  try {
    loading.value = true
    const { data } = await ValuationApi.getAssetDetail(entityId.value, props.currentData?.assetId)
    assetsDetail.value = data
    initData()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

/**
 * 弹出切换价格源确认弹窗
 */
const changePriceProviderSelect = () => {
  changeAssetPriceProviderDialogRef.value?.changeAssetPriceProviderDialogStatus()
}

/**
 * 确认切换价格源
 */
const onConfirmChangeAssetPriceProvider = async () => {
  try {
    loading.value = true
    await ValuationApi.updateAssetsPriceProvider(entityId.value, props.currentData.assetId, {
      priceProviderId: currentPriceProviderId.value
    })
    currentBeforePriceProviderId.value = currentPriceProviderId.value
    ElMessage.success(t('common.successfully'))
    emits('onUpdateAssetTableList')
  } catch (error: any) {
    currentPriceProviderId.value = currentBeforePriceProviderId.value
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

/**
 * 取消切换价格源
 */
const onCancelChangeAssetPriceProvider = () => {
  currentPriceProviderId.value = currentBeforePriceProviderId.value
}

watch(
  [() => isShowEditDrawer.value],
  () => {
    if (isShowEditDrawer.value && !isEmpty(props.currentData)) {
      nextTick(async () => {
        if (props.currentData?.assetId) {
          assetsDetail.value = props.currentData || {}
          fetchAssetsDetail()
        }
      })
    }
  },
  {
    immediate: true,
    flush: 'post'
  }
)

defineExpose({
  onCheckDrawerStatus
})
</script>

<style lang="scss" scoped>
.elv-leger-assets-drawer {
  .elv-leger-assets-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-leger-assets-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-leger-assets-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;

    .el-scrollbar__view {
      padding: 0 24px;
    }
  }

  .elv-leger-assets-drawer-info {
    margin-bottom: 16px;

    .elv-content-assets-item {
      margin-bottom: 16px;

      .content-title {
        font-size: 12px;
        color: #1e2024;
        font-weight: 500;
        font-family: 'Plus Jakarta Sans';
        line-height: 24px;
        margin-bottom: 6px;
      }
    }

    .elv-content-assets-overview {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 16px;
      position: relative;
      min-height: 60px;

      .elv-content-assets-overview-left {
        display: flex;
        flex-direction: column;

        .elv-base-cell-icon-title {
          padding: 0px;

          .elv-base-cell-icon-title-title {
            font-size: 24px !important;
            color: #1e2024;
            font-weight: 600;
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .elv-content-assets-overview-symbol-num {
          font-size: 13px;
          font-weight: 500;
          color: #838d95;
          margin-top: 10px;
          min-height: 16px;
        }
      }

      .elv-content-assets-overview-edit {
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 10px;
      }

      .elv-content-assets-overview-symbol {
        position: absolute;
        white-space: nowrap;
        right: 0px;
        bottom: 12px;
        border: 1px solid #d0d4d9;
        background-color: #fcf2f2;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 8px;
        font-size: 11px;
        color: #1e2024;
        height: 22px;
      }
    }

    .elv-content-assets-price {
      .content-price-price-provider {
        display: flex;
        align-items: center;
        padding: 0px 12px;
        height: 44px;
        border: 1px solid #dde1e6;
        border-radius: 4px;
        background-color: #f9fafb;
        font-size: 14px;
        font-weight: 400;

        .content-price-price-provider-derivative {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: auto;
            margin-right: 8px;
            border-radius: 50%;
          }
        }
      }

      .content-price-price-provider-select {
        padding: 0px;
        border: none;
        background-color: transparent;

        .elv-add-assets-dialog-content-price-provider-select {
          .elv-select-selected-item-tags-single {
            span {
              max-width: 280px;
            }
          }
        }

        .elv-add-assets-dialog-content-price-provider-popper {
          ul > li {
            .suffix-name {
              display: flex;
              align-items: center;
              margin-left: 8px;
              height: 19px;
              padding: 0px 8px;
              border-radius: 14px;
              background-color: #fff9f8;
              font-size: 10px;
              color: #eb3a30;
              line-height: 12px;
              font-weight: 400;
            }
          }

          .el-scrollbar__view {
            padding: 0px;
          }

          .el-select-dropdown__footer {
            padding: 0px;
          }
        }
      }

      .content-price-tip {
        font-size: 12px;
        color: #636b75;
        line-height: 24px;
        display: flex;
        align-items: center;
        margin-top: 4px;

        svg {
          padding-top: 1px;
          margin-right: 5px;
        }

        .waring-link {
          color: #636b75;
          margin-left: 5px;
          padding-top: 2px;
          line-height: 15px;
          border-bottom: 1px solid #636b75;
        }

        span {
          display: flex;
          align-items: center;
        }

        .content-price-price-provider-contain-tip {
          align-items: flex-start;

          svg {
            margin-top: 5px;
          }
        }
      }
    }

    .elv-content-assets-margin-assets {
      .elv-margin-assets-content {
        display: flex;
        align-items: center;
        padding: 0px 12px;
        height: 44px;
        border: 1px solid #dde1e6;
        border-radius: 4px;
        background-color: #f9fafb;
        font-size: 14px;
        font-weight: 400;

        .elv-base-cell-icon-title {
          padding: 12px 0px;

          .elv-base-cell-icon-title-title {
            font-weight: 400 !important;
          }
        }
      }
    }

    .elv-content-assets-contract {
      .elv-content-assets-contract-content {
        overflow: auto;

        .elv-content-assets-contract-content-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0px 12px;
          height: 44px;
          border: 1px solid #dde1e6;
          border-radius: 4px;
          background-color: #f9fafb;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;

          .elv-base-cell-icon-title {
            padding: 12px 0px;

            .elv-base-cell-icon-title-title {
              font-weight: 400 !important;
              max-width: 250px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .sources-copy {
            cursor: pointer;
          }
        }
      }

      .elv-content-assets-contract-empty {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 14px;
      }
    }
  }
}
</style>
