<template>
  <div class="elv-financials-valuation-assets-detail-content">
    <div class="elv-financials-valuation-assets-detail-header">
      <span class="top"
        >{{ t('common.totalAsset') }}: <span class="number">{{ showPageTableInfo.total }}</span></span
      >
      <elv-button style="margin-left: 16px" height="32" width="120" round plain type="primary" @click="addNewAssets">
        <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addNew') }}</elv-button
      >
    </div>
    <AssetsTable
      :tableData="tableList"
      :tableLoading="tableLoading"
      :pageParams="showPageTableInfo"
      @openDetailOverlay="openDetailOverlay"
      @onChangePage="onChangePage"
      @onChangePageSize="onChangePageSize"
    />
    <AddAssetsDialog
      ref="addAssetsRef"
      :price-provider-origin-list="priceProviderOriginList"
      @onClickConfirmHandler="onRefreshAssetsList"
    />
  </div>
  <AssetDetailOverlay
    ref="assetDetailRef"
    :currentData="detailShowInfo"
    :priceProviderList="priceProviderOriginList"
    @onOpenEditDialog="onOpenEditDialog"
    @onUpdateAssetTableList="updateAssetTableList"
  />
</template>
<script lang="ts" setup>
import ValuationApi from '@/api/ValuationApi'
import AssetsTable from './components/AssetsTable.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { PriceProviderItemType } from '#/ValuationTypes'
import AddAssetsDialog from './components/AddAssetsDialog.vue'
import { useValuationStore } from '@/stores/modules/valuation'
import AssetDetailOverlay from './components/AssetDetailOverlay.vue'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const valuationStore = useValuationStore()

const addAssetsRef = useTemplateRef('addAssetsRef')
const assetDetailRef = useTemplateRef('assetDetailRef')
const detailShowInfo = ref({})
const tableLoading = ref(false)
const currentEditData = ref<any>({})
const priceProviderOriginList = ref<PriceProviderItemType[]>([])

const showPageTableInfo = ref({
  page: 1,
  limit: 20,
  total: 0
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const tableList = computed(() => {
  return valuationStore.assetsDataList.list || []
})

/**
 * 打开添加资产弹窗
 */
const addNewAssets = () => {
  currentEditData.value = null
  addAssetsRef.value?.changeDialogStatus()
}

/**
 * 获取价格源列表数据
 */
const getPriceProviderList = async () => {
  const { data } = await ValuationApi.getPriceProviderList(entityId.value, { page: 1, limit: 500 })
  priceProviderOriginList.value = data.list.filter((item) => item.type !== 'API_EXCHANGE_RATE')
}

/**
 * 更新资产列表数据
 */
const updateAssetTableList = async () => {
  tableLoading.value = true
  try {
    const respondData = await valuationStore.fetchAssetsDataList(entityId.value, {
      page: showPageTableInfo.value.page,
      limit: showPageTableInfo.value.limit
    })
    showPageTableInfo.value.total = respondData?.totalCount || respondData.total
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

/**
 * 打开资产详情弹窗
 * @param data 资产数据
 */
const openDetailOverlay = (data: any) => {
  detailShowInfo.value = data
  assetDetailRef.value?.onCheckDrawerStatus()
}

/**
 * 确认添加资产点击事件回调
 */
const onRefreshAssetsList = () => {
  addAssetsRef.value?.changeDialogStatus()
  showPageTableInfo.value.page = 1
  updateAssetTableList()
}

/**
 * 分页切换页码
 * @param pageNum 页码
 */
const onChangePage = (pageNum: number) => {
  showPageTableInfo.value.page = pageNum
  updateAssetTableList()
}

/**
 * 分页切换每页显示数量
 * @param pageSize 每页显示数量
 */
const onChangePageSize = (pageSize: number) => {
  showPageTableInfo.value.limit = pageSize
  updateAssetTableList()
}

/**
 * 打开编辑资产弹窗
 * @param data 资产数据
 */
const onOpenEditDialog = (data: any) => {
  currentEditData.value = data
  assetDetailRef.value?.onCheckDrawerStatus()
  addAssetsRef.value?.changeDialogStatus(true, currentEditData.value)
}

watch(
  [() => route, () => entityStore.entityDetail?.entityId],
  async () => {
    try {
      if (route.name === 'entity-valuation-assets') {
        await updateAssetTableList()
        getPriceProviderList()
      }
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  valuationStore.clearAssetsDataList()
})
</script>
<style lang="scss" scoped>
.elv-financials-valuation-assets-detail-content {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-financials-valuation-assets-detail-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .top {
      color: #838d95;
      font-size: 12px;

      .number {
        color: #0e0f11;
        margin-left: 5px;
      }
    }
  }
}
</style>
